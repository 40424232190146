import { UNBREAKABLE_SEPARATOR } from '@src/constants/unbreakableSeparator';

export const PromoGuideSheetTexts = {
  title: 'Подробнее о бонусах Сравни',
  content: `Под рублями мы понимаем бонусные рубли, которые зачисляются на бонусный счет и могут быть использованы
        пользователями в соответствии с правилами, 1 бонусный рубль = 1 рублю РФ`,
  description: `Акция с бонусом 500 ₽ доступна при получении первого займа в${UNBREAKABLE_SEPARATOR}приложении и${UNBREAKABLE_SEPARATOR}его${UNBREAKABLE_SEPARATOR}погашении в${UNBREAKABLE_SEPARATOR}срок без${UNBREAKABLE_SEPARATOR}допущения просрочек`,
  buttonText: 'Понятно',
  linkText: 'Полные условия акции',
};
